import {
   MDBBtn,
   MDBCol,
   MDBContainer,
   MDBRow,
   MDBModal,
   MDBModalDialog,
   MDBModalContent,
   MDBModalHeader,
   MDBModalTitle,
   MDBModalBody,
   MDBModalFooter,
   MDBTextArea,
   MDBInput,
   MDBCardBody,
   MDBCard,
   MDBIcon,
} from "mdb-react-ui-kit";

import { useState } from "react";

export default function Banner() {
   const [centredModal, setCentredModal] = useState(false);

   const [formValue, setFormValue] = useState({
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      email: "",
      message: "",
   });

   const toggleShow = () => {
      setCentredModal(!centredModal);
      setFormValue({
         firstName: "",
         lastName: "",
         contactNumber: "",
         address: "",
         email: "",
         message: "",
      });
      setLoading(false);
   };

   const [loading, setLoading] = useState(false);
   const [sent, setSent] = useState(false);

   const onChange = (e) => {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
   };

   function sendMessage(e) {
      e.preventDefault();
      setLoading(true);

      fetch(`${process.env.REACT_APP_API_URL}/inquiry/`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            email: formValue.email,
            contactNumber: formValue.contactNumber,
            address: formValue.address,
            message: formValue.message,
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            if (data) {
               setSent(true);
            } else {
               setSent(false);
            }
         });
   }

   function updateSent(e) {
      setSent(false);
      toggleShow();
   }

   return (
      <>
         <div className="backgroundBanner d-flex align-items-center">
            <MDBContainer className="bannerHeading">
               <h2 className="headingText">Get out on Power Interruption</h2>
               <h1 className="headingText p-0 m-0">
                  Get <strong className="emphasizeSolar">SOLAR</strong>
               </h1>

               <div className="my-5">
                  <h3 className="light-text">Want to know more?</h3>
                  <MDBBtn color="warning" rounded onClick={toggleShow}>
                     Contact Us
                  </MDBBtn>
               </div>
            </MDBContainer>
         </div>

         {/* Modal */}
         <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered>
               <MDBModalContent>
                  <div className="text-end pt-3 pe-3">
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow}
                     ></MDBBtn>
                  </div>

                  <MDBModalBody>
                     <MDBCard
                        className="text-black needs-validation"
                        style={{ borderRadius: "25px" }}
                     >
                        {sent ? (
                           <></>
                        ) : (
                           <h2 className="pt-3 text-center fw-normal letterSpacing">
                              Have a Question? <br /> Shoot us a Message!
                           </h2>
                        )}

                        {!sent ? (
                           <MDBCardBody>
                              <MDBRow
                                 tag="form"
                                 className="g-3 justify-content-center align-items-center"
                                 onSubmit={(e) => sendMessage(e)}
                              >
                                 <MDBCol md="6">
                                    <MDBInput
                                       value={formValue.firstName}
                                       name="firstName"
                                       onChange={onChange}
                                       label="First Name"
                                       id="firstNameId"
                                       type="text"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="6">
                                    <MDBInput
                                       value={formValue.lastName}
                                       name="lastName"
                                       onChange={onChange}
                                       label="Last Name"
                                       id="lastNameId"
                                       type="text"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>

                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.email}
                                       name="email"
                                       onChange={onChange}
                                       label="Email"
                                       id="emailId"
                                       type="email"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.contactNumber}
                                       name="contactNumber"
                                       onChange={onChange}
                                       label="Contact Number"
                                       id="contactId"
                                       type="number"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.address}
                                       name="address"
                                       onChange={onChange}
                                       label="Address"
                                       id="addressId"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBTextArea
                                       value={formValue.message}
                                       name="message"
                                       onChange={onChange}
                                       label="Message"
                                       id="messageId"
                                       type="text"
                                       rows={8}
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol size="12" className="text-center">
                                    <MDBBtn
                                       rounded
                                       type="submit"
                                       className="blueButton"
                                       disabled={loading}
                                    >
                                       {loading ? "Sending..." : "Submit"}
                                    </MDBBtn>
                                 </MDBCol>
                              </MDBRow>
                           </MDBCardBody>
                        ) : (
                           <MDBCardBody>
                              <h1 className="my-5  ">Message Sent!</h1>
                              <h4 className="my-5  ">I'll get touch on you.</h4>
                              <MDBBtn
                                 className="blueButton"
                                 onClick={updateSent}
                              >
                                 Got it!
                              </MDBBtn>
                           </MDBCardBody>
                        )}
                     </MDBCard>
                  </MDBModalBody>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>
      </>
   );
}
