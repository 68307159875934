import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
   MDBBtn,
   MDBRow,
   MDBCol,
   MDBCard,
   MDBCardBody,
   MDBInput,
   MDBTextArea,
   MDBInputGroup,
   MDBContainer,
} from "mdb-react-ui-kit";

import { Form } from "react-bootstrap";

import AdminNavbar from "../components/adminComponents/AdminNavbar";

export default function AddProject() {
   const retrieveToken = localStorage.getItem("token");
   const [isAdmin, setIsAdmin] = useState(true);
   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/admin/details`, {
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
         },
      })
         .then((res) => res.json())
         .then((data) => {
            if (data !== undefined) {
               setIsAdmin(data.isAdmin);
            } else {
               setIsAdmin(false);
            }
         });
   }, [isAdmin]);

   const [formValue, setFormValue] = useState({
      projectTitle: "",
      projectDescription: "",
      projectDate: "",
      projectImageLink: "",
      projectLocation: "",
      projectType: "",
   });

   const [saving, setSaving] = useState(false);

   const onChange = (e) => {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
   };

   function addProject(e) {
      e.preventDefault();
      setSaving(true);
      fetch(`${process.env.REACT_APP_API_URL}/project/addProject`, {
         method: "POST",
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            projectTitle: formValue.projectTitle,
            projectDescription: formValue.projectDescription,
            projectDate: formValue.projectDate,
            projectImageLink: formValue.projectImageLink,
            projectLocation: formValue.projectLocation,
            projectType: formValue.projectType,
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            if (data) {
               Swal.fire({
                  icon: "success",
                  title: "Successfully added",
               });
               setFormValue({
                  projectTitle: "",
                  projectDescription: "",
                  projectDate: "",
                  projectImageLink: "",
                  projectLocation: "",
                  projectType: "",
               });
               setSaving(false);
            } else {
               Swal.fire({
                  icon: "error",
                  title: "Failed to add",
                  text: "Something went wrong!",
               });
               window.location.reload();
            }
         });
   }

   return !isAdmin ? (
      <Navigate to="/" />
   ) : (
      <>
         <AdminNavbar />
         <MDBContainer className="mt-5 pt-5">
            <MDBCard
               className="text-black  pt-2 needs-validation"
               style={{ borderRadius: "25px" }}
            >
               <MDBCardBody>
                  <MDBRow
                     tag="form"
                     className="g-3 justify-content-center align-items-center"
                     onSubmit={(e) => addProject(e)}
                  >
                     <h3 className="fw-bold mb-3 mx-1 mx-md-4 ">
                        Add your completed project
                     </h3>
                     <MDBCol md={6}>
                        <MDBInput
                           value={formValue.projectTitle}
                           name="projectTitle"
                           onChange={onChange}
                           label="Project Title"
                           id="projectTitleId"
                           type="text"
                           className="w-100"
                           required
                        />
                     </MDBCol>
                     <MDBCol md={6}>
                        <MDBInput
                           value={formValue.projectLocation}
                           name="projectLocation"
                           onChange={onChange}
                           label="Project Location"
                           id="projectLocationId"
                           type="text"
                           className="w-100"
                           required
                        />
                     </MDBCol>
                     <MDBCol md={3}>
                        <Form.Select
                           aria-label="Default select example"
                           onChange={onChange}
                           value={formValue.projectType}
                           name="projectType"
                           required
                        >
                           <option value="">Project Type</option>
                           <option value="Installation">Installation</option>
                           <option value="Operational">Operational</option>
                           <option value="Commissioning">Commissioning</option>
                        </Form.Select>
                     </MDBCol>
                     <MDBCol md={3}>
                        <MDBInput
                           value={formValue.projectDate}
                           name="projectDate"
                           onChange={onChange}
                           label="Date Completed"
                           id="projectDateId"
                           type="date"
                           className="w-100"
                           required
                        />
                     </MDBCol>
                     <MDBCol md={6}>
                        <MDBInput
                           value={formValue.projectImageLink}
                           name="projectImageLink"
                           onChange={onChange}
                           label="Project Image Link"
                           id="imageId"
                           type="url"
                           className="w-100"
                           required
                        />
                     </MDBCol>
                     <MDBCol md={12}>
                        <MDBTextArea
                           value={formValue.projectDescription}
                           name="projectDescription"
                           onChange={onChange}
                           label="Project Description"
                           id="projectDescriptionId"
                           type="text"
                           rows={8}
                           className="w-100"
                           required
                        />
                     </MDBCol>
                     <MDBCol size="12" className="text-center">
                        {saving ? (
                           <MDBBtn
                              type="submit"
                              disabled
                              color="warning"
                              outline
                           >
                              Saving..
                           </MDBBtn>
                        ) : (
                           <MDBBtn
                              type="submit"
                              className="buttonOutline"
                              color="warning"
                              outline
                           >
                              Save
                           </MDBBtn>
                        )}
                     </MDBCol>
                  </MDBRow>
               </MDBCardBody>
            </MDBCard>
         </MDBContainer>
      </>
   );
}
