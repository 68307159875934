import React, { useState } from "react";
import {
   MDBContainer,
   MDBNavbar,
   MDBNavbarBrand,
   MDBNavbarToggler,
   MDBNavbarNav,
   MDBNavbarItem,
   MDBNavbarLink,
   MDBCollapse,
   MDBIcon,
   MDBBtn,
   MDBModal,
   MDBModalDialog,
   MDBModalContent,
   MDBModalHeader,
   MDBModalTitle,
   MDBModalBody,
   MDBTextArea,
   MDBRow,
   MDBCol,
   MDBInput,
   MDBCardBody,
   MDBCard,
} from "mdb-react-ui-kit";

import businesIcon from "../images/ICON.png";

export default function Navbar() {
   const [showNav, setShowNav] = useState(false);
   const [centredModal, setCentredModal] = useState(false);

   const [formValue, setFormValue] = useState({
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      email: "",
      message: "",
   });

   const toggleShow = () => {
      setCentredModal(!centredModal);
      setFormValue({
         firstName: "",
         lastName: "",
         contactNumber: "",
         address: "",
         email: "",
         message: "",
      });
      setLoading(false);
   };

   const [loading, setLoading] = useState(false);
   const [sent, setSent] = useState(false);

   const onChange = (e) => {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
   };

   function sendMessage(e) {
      e.preventDefault();
      setLoading(true);

      fetch(`${process.env.REACT_APP_API_URL}/inquiry/`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            email: formValue.email,
            contactNumber: formValue.contactNumber,
            address: formValue.address,
            message: formValue.message,
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            if (data) {
               setSent(true);
            } else {
               setSent(false);
            }
         });
   }

   function updateSent(e) {
      setSent(false);
      toggleShow();
   }

   return (
      <>
         <MDBNavbar expand="lg" light bgColor="light" fixed="top">
            <MDBContainer className="d-flex justift-content-between">
               <MDBNavbarBrand href="/">
                  <img className="navBrandIcon" src={businesIcon} alt="" />
               </MDBNavbarBrand>
               <MDBNavbarToggler
                  type="button"
                  aria-expanded=""
                  aria-label="Toggle navigation"
                  onClick={() => setShowNav(!showNav)}
               >
                  {showNav ? (
                     <MDBIcon fas icon="times" />
                  ) : (
                     <MDBIcon icon="bars" fas />
                  )}
               </MDBNavbarToggler>
               <MDBCollapse navbar show={showNav}>
                  <MDBNavbarNav>
                     <MDBNavbarItem>
                        <MDBNavbarLink aria-current="page" href="/">
                           Home
                        </MDBNavbarLink>
                     </MDBNavbarItem>
                     <MDBNavbarItem>
                        <MDBNavbarLink aria-current="page" href="/#about">
                           About
                        </MDBNavbarLink>
                     </MDBNavbarItem>
                     <MDBNavbarItem>
                        <MDBNavbarLink aria-current="page" href="/projects">
                           Projects
                        </MDBNavbarLink>
                     </MDBNavbarItem>
                     <MDBNavbarItem>
                        <MDBNavbarLink aria-current="page" href="#contact">
                           Contact Us
                        </MDBNavbarLink>
                     </MDBNavbarItem>
                  </MDBNavbarNav>
               </MDBCollapse>
               <MDBBtn
                  onClick={toggleShow}
                  className="d-none d-lg-block"
                  rounded
                  color="warning"
               >
                  Get Qoute
               </MDBBtn>
            </MDBContainer>
         </MDBNavbar>
         {/* Modal */}
         <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered>
               <MDBModalContent>
                  <div className="pt-3 pe-3 text-end">
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow}
                     ></MDBBtn>
                  </div>

                  <MDBModalBody>
                     <MDBCard
                        className="text-black needs-validation"
                        style={{ borderRadius: "25px" }}
                     >
                        <div className="d-flex flex-column align-items-center">
                           <h1 className=" text-center pt-3 letterSpacing">
                              Get a Quote Fast!
                           </h1>
                           <span className="underline"></span>
                        </div>
                        {!sent ? (
                           <MDBCardBody>
                              <MDBRow
                                 tag="form"
                                 className="g-3 justify-content-center align-items-center"
                                 onSubmit={(e) => sendMessage(e)}
                              >
                                 <MDBCol md="6">
                                    <MDBInput
                                       value={formValue.firstName}
                                       name="firstName"
                                       onChange={onChange}
                                       label="First Name"
                                       id="firstNameId"
                                       type="text"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="6">
                                    <MDBInput
                                       value={formValue.lastName}
                                       name="lastName"
                                       onChange={onChange}
                                       label="Last Name"
                                       id="lastNameId"
                                       type="text"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>

                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.email}
                                       name="email"
                                       onChange={onChange}
                                       label="Email"
                                       id="emailId"
                                       type="email"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.contactNumber}
                                       name="contactNumber"
                                       onChange={onChange}
                                       label="Contact Number"
                                       id="contactId"
                                       type="number"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.address}
                                       name="address"
                                       onChange={onChange}
                                       label="Address"
                                       id="addressId"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBTextArea
                                       value={formValue.message}
                                       name="message"
                                       onChange={onChange}
                                       label="Message"
                                       id="messageId"
                                       type="text"
                                       rows={8}
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol size="12" className="text-center">
                                    <MDBBtn
                                       rounded
                                       type="submit"
                                       className="blueButton"
                                       disabled={loading}
                                    >
                                       {loading ? "Sending..." : "Submit"}
                                    </MDBBtn>
                                 </MDBCol>
                              </MDBRow>
                           </MDBCardBody>
                        ) : (
                           <MDBCardBody>
                              <h1 className="my-5  ">Message Sent!</h1>
                              <h4 className="my-5  ">I'll get touch on you.</h4>
                              <MDBBtn
                                 className="blueButton"
                                 onClick={updateSent}
                              >
                                 Got it!
                              </MDBBtn>
                           </MDBCardBody>
                        )}
                     </MDBCard>
                  </MDBModalBody>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>
      </>
   );
}
