import {
   MDBCard,
   MDBCardBody,
   MDBCardText,
   MDBBtn,
   MDBCol,
   MDBCardSubTitle,
   MDBModal,
   MDBModalDialog,
   MDBModalContent,
   MDBModalHeader,
   MDBModalTitle,
   MDBModalBody,
   MDBModalFooter,
   MDBIcon,
} from "mdb-react-ui-kit";
import { useState } from "react";

export default function ProjectCard({ inquiryProps }) {
   const retrieveToken = localStorage.getItem("token");

   const [deleting, setDeleting] = useState(false);

   const {
      _id,
      firstName,
      lastName,
      email,
      contactNumber,
      address,
      message,
      createdOn,
   } = inquiryProps;

   const [centredModal, setCentredModal] = useState(false);
   const [centredModal1, setCentredModal1] = useState(false);

   const toggleShow = () => setCentredModal(!centredModal);
   const toggleShow1 = () => setCentredModal1(!centredModal1);

   function deleteQuery() {
      setDeleting(true);
      fetch(`${process.env.REACT_APP_API_URL}/inquiry/${_id}`, {
         method: "DELETE",
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
         },
         "Content-Type": "application/json",
      })
         .then((res) => res.json())
         .then((result) => {
            if (result) {
               setCentredModal1(!centredModal1);
               setDeleting(false);
               window.location.reload();
            } else {
               console.err("Failed to Delete");
               setCentredModal1(!centredModal1);
               setDeleting(false);
            }
         });
   }

   return (
      <>
         <MDBCol lg={4} md={6}>
            <MDBCard className="m-2 cardBody  " border="secondary" shadow="5">
               <MDBCardBody>
                  <h5>
                     Name: {firstName} {lastName}
                  </h5>

                  <MDBCardSubTitle>
                     Contact Number: {contactNumber}
                  </MDBCardSubTitle>
                  <MDBCardText>Email: {email}</MDBCardText>
                  <p>{createdOn}</p>
                  <MDBBtn
                     className="buttonOutline"
                     outline
                     color="warning"
                     onClick={toggleShow}
                  >
                     View Query
                  </MDBBtn>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>

         {/* Modal */}

         <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered scrollable>
               <MDBModalContent>
                  <MDBModalHeader>
                     <MDBModalTitle>
                        {firstName} {lastName}
                     </MDBModalTitle>
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow}
                     ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                     <h6 className="fw-normal">
                        Contact Number: {contactNumber}
                     </h6>
                     <h6 className="fw-normal">Address: {address}</h6>
                     <h6 className=" p-0 fw-normal">Message:</h6>
                     <p className="m-0 p-0">{message}</p>
                  </MDBModalBody>
                  <MDBModalFooter>
                     <MDBBtn
                        color="danger"
                        onClick={() => {
                           setCentredModal(!centredModal);
                           setTimeout(() => {
                              setCentredModal1(!centredModal1);
                           }, 50);
                        }}
                     >
                        <MDBIcon far icon="trash-alt" size="lg" />
                     </MDBBtn>
                  </MDBModalFooter>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>

         <MDBModal
            tabIndex="-1"
            show={centredModal1}
            setShow={setCentredModal1}
         >
            <MDBModalDialog centered scrollable>
               <MDBModalContent>
                  <div className="text-end p-2">
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow1}
                     ></MDBBtn>
                  </div>
                  <MDBModalBody className="text-center">
                     <h2 className="fw-normal text-center">Are you sure?</h2>
                     <h5 className="fw-light mt-2">This can't be undone.</h5>
                  </MDBModalBody>
                  <MDBModalFooter>
                     <MDBBtn color="secondary" onClick={toggleShow1}>
                        Cancel
                     </MDBBtn>
                     {deleting ? (
                        <MDBBtn color="danger" disabled onClick={deleteQuery}>
                           Removing...
                        </MDBBtn>
                     ) : (
                        <MDBBtn color="danger" onClick={deleteQuery}>
                           Yes
                        </MDBBtn>
                     )}
                  </MDBModalFooter>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>
      </>
   );
}
