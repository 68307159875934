import {
   MDBBtn,
   MDBCard,
   MDBCardBody,
   MDBCol,
   MDBContainer,
   MDBRow,
   MDBModal,
   MDBModalDialog,
   MDBModalContent,
   MDBModalHeader,
   MDBModalTitle,
   MDBModalBody,
   MDBModalFooter,
   MDBInput,
   MDBTextArea,
} from "mdb-react-ui-kit";

import { Form } from "react-bootstrap";

import { useState, useEffect } from "react";
import loader from "../images/Dual Ring-0.5s-35px.gif";

export default function ProjectCard({ projectProps }) {
   const {
      id,
      projectTitle,
      projectDescription,
      projectDate,
      projectImageLink,
      projectLocation,
      proje,
   } = projectProps;

   const retrieveToken = localStorage.getItem("token");

   const [isAdmin, setIsAdmin] = useState(false);
   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/admin/details`, {
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
         },
      })
         .then((res) => res.json())
         .then((data) => {
            if (data !== undefined) {
               setIsAdmin(data.isAdmin);
            } else {
               setIsAdmin(false);
            }
         });
   }, [isAdmin]);

   const [centredModal, setCentredModal] = useState(false);

   const toggleShow = () => setCentredModal(!centredModal);

   return (
      <>
         <MDBCol lg={4} md={6} className="my-2">
            <MDBCard border="secondary">
               <div className="card-img-top  d-flex justify-content-center align-items-center">
                  <img
                     src={projectImageLink}
                     alt="..."
                     className="projectImages img-fluid"
                     onClick={toggleShow}
                  />
                  <h4 className="readMore" onClick={toggleShow}>
                     Read more
                  </h4>
               </div>

               <div className="card-body">
                  <h5 className="card-title">{projectTitle}</h5>
                  <h6 className="my-2">{projectDate}</h6>
                  <p className="my-2">{projectLocation}</p>
               </div>
            </MDBCard>
         </MDBCol>
         {/* Modal Readmore */}
         <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered>
               <MDBModalContent>
                  <MDBModalHeader>
                     <MDBModalTitle>{projectTitle}</MDBModalTitle>
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow}
                     ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                     <div className="modalImage m-0 p-0 img-fluid ">
                        <img
                           src={projectImageLink}
                           className="img-thumbnail"
                           alt=""
                        />
                     </div>

                     <h6 className="mt-3 fw-normal">Description:</h6>
                     <p>{projectDescription}</p>
                  </MDBModalBody>
                  <MDBModalFooter>
                     <MDBBtn color="secondary" onClick={toggleShow}>
                        Got it
                     </MDBBtn>
                  </MDBModalFooter>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>
      </>
   );
}
