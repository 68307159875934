import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import ProjectCard from "./ProjectsCard";
import { useNavigate } from "react-router-dom";

import loader from "../images/Blocks-1s-250px.gif";

export default function Projects() {
   const [projects, setProjects] = useState([]);
   const [loading, setLoading] = useState(true);
   const navigate = useNavigate();

   useEffect(() => {
      async function fetchProjects() {
         try {
            const response = await fetch(
               `${process.env.REACT_APP_API_URL}/project/`
            );
            const data = await response.json();

            if (data.length === 0) {
               setLoading(false);
            } else {
               const randomProjects = data
                  .sort(() => 0.5 - Math.random())
                  .slice(0, 6);
               setProjects(
                  randomProjects.map((project) => (
                     <ProjectCard key={project._id} projectProps={project} />
                  ))
               );
               setLoading(false);
            }
         } catch (error) {
            console.error(error);
         }
      }

      fetchProjects();
   }, []);

   const navigatePage = () => {
      navigate("/projects");
   };

   return (
      <div className="mt-5">
         {loading ? (
            <div className="loaderPage d-flex flex-column justify-content-center align-items-center">
               <img src={loader} alt="" />
               <h3>Loading Projects</h3>
            </div>
         ) : (
            <>
               {projects.length === 0 ? (
                  <h1 className="text-center">No projects available</h1>
               ) : (
                  <div>
                     <div className="text-center d-flex flex-column align-items-center  mb-4 ">
                        <h1 id="projects" className="fw-bold">
                           Projects
                        </h1>
                        <span className="underline text-center"></span>
                     </div>

                     <MDBContainer className="min-80vh mb-5">
                        <MDBRow>{projects}</MDBRow>
                     </MDBContainer>
                  </div>
               )}
               <div className="text-center">
                  <MDBBtn
                     className="text-center m-4"
                     color="warning"
                     onClick={navigatePage}
                  >
                     View More Projects
                  </MDBBtn>
               </div>
            </>
         )}
      </div>
   );
}
