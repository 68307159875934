import image1 from "../images/galler1_crop.jpg";
import image2 from "../images/1crop.jpg";
import image3 from "../images/2crop.jpg";
import {
   MDBContainer,
   MDBNavbar,
   MDBNavbarBrand,
   MDBNavbarToggler,
   MDBNavbarNav,
   MDBNavbarItem,
   MDBNavbarLink,
   MDBCollapse,
   MDBIcon,
   MDBBtn,
   MDBModal,
   MDBModalDialog,
   MDBModalContent,
   MDBModalHeader,
   MDBModalTitle,
   MDBModalBody,
   MDBTextArea,
   MDBRow,
   MDBCol,
   MDBInput,
   MDBCardBody,
   MDBCard,
} from "mdb-react-ui-kit";

import React, { useState } from "react";

export default function About() {
   const [centredModal, setCentredModal] = useState(false);

   const [formValue, setFormValue] = useState({
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      email: "",
      message: "",
   });

   const toggleShow = () => {
      setCentredModal(!centredModal);
      setFormValue({
         firstName: "",
         lastName: "",
         contactNumber: "",
         address: "",
         email: "",
         message: "",
      });
      setLoading(false);
   };

   const [loading, setLoading] = useState(false);
   const [sent, setSent] = useState(false);

   const onChange = (e) => {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
   };

   function sendMessage(e) {
      e.preventDefault();
      setLoading(true);

      fetch(`${process.env.REACT_APP_API_URL}/inquiry/`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            email: formValue.email,
            contactNumber: formValue.contactNumber,
            address: formValue.address,
            message: formValue.message,
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            if (data) {
               setSent(true);
            } else {
               setSent(false);
            }
         });
   }

   function updateSent(e) {
      setSent(false);
      toggleShow();
   }

   return (
      <>
         <MDBContainer
            id="about"
            className="d-flex justify-content-center about-page my-3 "
         >
            <MDBRow className="my-3">
               <MDBCol
                  lg={6}
                  className=" my-auto px-5 py-auto text-center text-md-start"
               >
                  <h3 className="fw-light">Make your house</h3>
                  <h2 className="mb-4 fw-bold ">BROWNOUT-FREE</h2>
                  <h4 className="mb-3 letterSpacing">
                     Why{" "}
                     <span className="emphasize">
                        BZ Solar Power Engineering Services
                     </span>
                     ?
                  </h4>
                  <p className="fw-light letterSpacing">
                     From installation to maintenance and beyond, we offer a
                     full range of solar services to meet all of your energy
                     needs. Learn more about what we do and how we can help you.
                  </p>

                  <MDBBtn onClick={toggleShow} color="warning" rounded>
                     Get Qoute
                  </MDBBtn>
               </MDBCol>

               <MDBCol
                  lg={6}
                  className="position-relative d-lg-flex my-4 d-none flex-row justify-content-center"
               >
                  <div className="backgroundFrame d-lg-block d-none"></div>
                  <MDBCol
                     md={8}
                     className="p-0 m-0 d-md-flex flex-column justify-content-center"
                  >
                     <img
                        id="about-images"
                        className="image-shadow"
                        src={image1}
                        alt=""
                     />
                  </MDBCol>

                  <MDBCol
                     md={4}
                     className="ms-2 d-none d-lg-flex flex-column justify-content-center"
                  >
                     <img
                        className="image-shadow img-fluid "
                        src={image2}
                        alt=""
                     />
                     <img
                        className="image-shadow img-fluid"
                        src={image3}
                        alt=""
                     />
                  </MDBCol>
               </MDBCol>
            </MDBRow>
         </MDBContainer>
         <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered>
               <MDBModalContent>
                  <div className="pt-3 pe-3 text-end">
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow}
                     ></MDBBtn>
                  </div>

                  <MDBModalBody>
                     <MDBCard
                        className="text-black needs-validation"
                        style={{ borderRadius: "25px" }}
                     >
                        <div className="d-flex flex-column align-items-center">
                           <h1 className=" text-center pt-3 letterSpacing">
                              Get a Quote Fast!
                           </h1>
                           <span className="underline"></span>
                        </div>

                        {!sent ? (
                           <MDBCardBody>
                              <MDBRow
                                 tag="form"
                                 className="g-3 justify-content-center align-items-center"
                                 onSubmit={(e) => sendMessage(e)}
                              >
                                 <MDBCol md="6">
                                    <MDBInput
                                       value={formValue.firstName}
                                       name="firstName"
                                       onChange={onChange}
                                       label="First Name"
                                       id="firstNameId"
                                       type="text"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="6">
                                    <MDBInput
                                       value={formValue.lastName}
                                       name="lastName"
                                       onChange={onChange}
                                       label="Last Name"
                                       id="lastNameId"
                                       type="text"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>

                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.email}
                                       name="email"
                                       onChange={onChange}
                                       label="Email"
                                       id="emailId"
                                       type="email"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.contactNumber}
                                       name="contactNumber"
                                       onChange={onChange}
                                       label="Contact Number"
                                       id="contactId"
                                       type="number"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.address}
                                       name="address"
                                       onChange={onChange}
                                       label="Address"
                                       id="addressId"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBTextArea
                                       value={formValue.message}
                                       name="message"
                                       onChange={onChange}
                                       label="Message"
                                       id="messageId"
                                       type="text"
                                       rows={8}
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol size="12" className="text-center">
                                    <MDBBtn
                                       rounded
                                       type="submit"
                                       className="blueButton"
                                       disabled={loading}
                                    >
                                       {loading ? "Sending..." : "Submit"}
                                    </MDBBtn>
                                 </MDBCol>
                              </MDBRow>
                           </MDBCardBody>
                        ) : (
                           <MDBCardBody>
                              <h1 className="my-5  ">Message Sent!</h1>
                              <h4 className="my-5  ">I'll get touch on you.</h4>
                              <MDBBtn
                                 className="blueButton"
                                 onClick={updateSent}
                              >
                                 Got it!
                              </MDBBtn>
                           </MDBCardBody>
                        )}
                     </MDBCard>
                  </MDBModalBody>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>
      </>
   );
}
