import { MDBCard, MDBCardBody, MDBCol, MDBContainer } from "mdb-react-ui-kit";

import solarIcon from "../images/icons/solar-panel-solid.svg";
import pesoIcon from "../images/icons/peso-sign-solid.svg";
import zeroIcon from "../images/icons/creative-commons-zero.svg";

export default function Services() {
   return (
      <MDBContainer className="my-5">
         <div className="d-flex flex-column align-items-center">
            <div className="text-center d-flex flex-column align-items-center  mb-4 ">
               <h1 id="projects" className="fw-bold">
                  Our Services
               </h1>
               <span className="underline2 text-center"></span>
            </div>
            <div className="text-light-weight text-center my-4">
               <h4 className="text-light-weight">
                  <strong className="mx-2 ">
                     <em className="text-center">
                        BZ Solar Power Engineering Services
                     </em>
                  </strong>
               </h4>
               <h4 className="text-light-weight">
                  offers the best solar service that saves your money and
                  reliable power delivery
               </h4>
            </div>
         </div>

         <div className="d-lg-flex d-block">
            <MDBCol
               lg={4}
               md={12}
               className="my-2 d-flex flex-column mx-auto services-card "
            >
               <MDBCard className="m-3">
                  <div className="card-body-services d-flex flex-column justify-content-center p-5">
                     <div className="text-center">
                        <img src={solarIcon} alt="" className="servicesIcons" />
                     </div>

                     <h3 className="servicesText text-center mt-4 py-3">
                        Solar Solutions
                     </h3>
                     <p className="servicesText my-2 text-center">
                        We provide solar solutions based on your needs
                     </p>
                  </div>
               </MDBCard>
            </MDBCol>
            <MDBCol
               lg={4}
               md={12}
               className="my-2 d-flex flex-column  mx-auto services-card "
            >
               <MDBCard className="m-3">
                  <div className="card-body-services d-flex flex-column justify-content-center  p-5">
                     <div className="text-center">
                        <img src={pesoIcon} alt="" className="servicesIcons" />
                     </div>

                     <h3 className="servicesText text-center mt-4 py-3">
                        Affordable
                     </h3>
                     <p className="servicesText my-2 text-center">
                        Get your solars with a friendly price
                     </p>
                  </div>
               </MDBCard>
            </MDBCol>
            <MDBCol
               lg={4}
               md={12}
               className="my-2 d-flex flex-column  mx-auto services-card "
            >
               <MDBCard className="m-3">
                  <div className="card-body-services d-flex flex-column justify-content-center  p-5">
                     <div className="text-center">
                        <img src={zeroIcon} alt="" className="servicesIcons" />
                     </div>

                     <h3 className="servicesText text-center mt-4 py-3">
                        Zero Brownout
                     </h3>
                     <p className="servicesText my-2 text-center">
                        Smooth transitions from main electrical supply to your
                        solar supply
                     </p>
                  </div>
               </MDBCard>
            </MDBCol>
         </div>
      </MDBContainer>
   );
}
