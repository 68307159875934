import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import AllProjects from "../components/AllProjects";

export default function ProjectLists() {
   return (
      <>
         <Navbar />
         <AllProjects />
         <Footer />
      </>
   );
}
