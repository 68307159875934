import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import ProjectCardAdmin from "./ProjectsCardAdmin";

import loader from "../../images/Blocks-1s-250px.gif";

export default function AllProjectsAdmin() {
   const [projects, setProjects] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/project/`)
         .then((res) => res.json())
         .then((data) => {
            if (data.length === 0) {
               setLoading(false);
            } else {
               setProjects(
                  data.map((project) => {
                     return (
                        <ProjectCardAdmin
                           key={project._id}
                           projectProps={project}
                        />
                     );
                  })
               );
               setLoading(false);
            }
         });
   }, []);

   return (
      <div className="mt-5 pt-5">
         {loading ? (
            <div className="loaderPage d-flex flex-column justify-content-center align-items-center">
               <img src={loader} alt="" />
               <h1>Loading Projects</h1>
            </div>
         ) : (
            <>
               {projects.length === 0 ? (
                  <h1 className="text-center">No projects available</h1>
               ) : (
                  <div>
                     <h1 id="projects" className="text-center my-3 fw-normal">
                        Projects
                     </h1>
                     <MDBContainer className="min-80vh mb-5">
                        <MDBRow>{projects}</MDBRow>
                     </MDBContainer>
                  </div>
               )}
            </>
         )}
      </div>
   );
}
