import {
   MDBBtn,
   MDBCard,
   MDBCardBody,
   MDBCol,
   MDBContainer,
   MDBRow,
   MDBModal,
   MDBModalDialog,
   MDBModalContent,
   MDBModalHeader,
   MDBModalTitle,
   MDBModalBody,
   MDBModalFooter,
   MDBInput,
   MDBTextArea,
   MDBIcon,
} from "mdb-react-ui-kit";

import { Form } from "react-bootstrap";

import { useState, useEffect } from "react";
import loader from "../../images/Dual Ring-0.5s-35px.gif";
import Swal from "sweetalert2";

export default function ProjectCard({ projectProps }) {
   const {
      _id,
      projectTitle,
      projectDescription,
      projectDate,
      projectImageLink,
      projectLocation,
      projectType,
   } = projectProps;

   const retrieveToken = localStorage.getItem("token");

   const [isAdmin, setIsAdmin] = useState(false);
   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/admin/details`, {
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
         },
      })
         .then((res) => res.json())
         .then((data) => {
            if (data !== undefined) {
               setIsAdmin(data.isAdmin);
            } else {
               setIsAdmin(false);
            }
         });
   }, [isAdmin]);

   const [formValue, setFormValue] = useState({
      projectTitle: projectTitle,
      projectDescription: projectDescription,
      projectDate: "",
      projectImageLink: projectImageLink,
      projectLocation: projectLocation,
      projectType: projectType,
   });

   const [saving, setSaving] = useState(false);
   const [deleting, setDeleting] = useState(false);

   const onChange = (e) => {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
   };

   const [centredModal, setCentredModal] = useState(false);
   const [centredModal1, setCentredModal1] = useState(false);
   const [centredModal2, setCentredModal2] = useState(false);

   const toggleShow = () => setCentredModal(!centredModal);
   const toggleShow1 = () => setCentredModal1(!centredModal1);
   const toggleShow2 = () => setCentredModal2(!centredModal2);

   const updateProject = (e) => {
      e.preventDefault();
      setSaving(true);
      fetch(`${process.env.REACT_APP_API_URL}/project/updateProject/${_id}`, {
         method: "PUT",
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            projectTitle: formValue.projectTitle,
            projectDescription: formValue.projectDescription,
            projectDate: formValue.projectDate,
            projectImageLink: formValue.projectImageLink,
            projectLocation: formValue.projectLocation,
            projectType: formValue.projectType,
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            if (data) {
               setSaving(false);
               Swal.fire({
                  icon: "success",
                  title: "Updated successfully!",
                  preConfirm: () => {
                     window.location.reload();
                  },
               });
            } else {
               Swal.fire({
                  icon: "error",
                  title: "Failed to update.",
               });
               setSaving(false);
            }
         });
   };

   const deleteProject = (e) => {
      e.preventDefault();
      setDeleting(true);
      fetch(`${process.env.REACT_APP_API_URL}/project/archiveProject/${_id}`, {
         method: "PUT",
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
            "Content-Type": "application/json",
         },
      })
         .then((res) => res.json())
         .then((data) => {
            if (data) {
               window.location.reload();
            }
         });
   };

   return (
      <>
         <MDBCol lg={4} md={6} className="my-2">
            <MDBCard border="secondary">
               <div className="card-img-top  d-flex justify-content-center align-items-center">
                  <img
                     src={projectImageLink}
                     alt="..."
                     className="projectImages img-fluid"
                     onClick={toggleShow}
                  />
                  <h4 className="readMore" onClick={toggleShow}>
                     Read more
                  </h4>
               </div>

               <div className="card-body">
                  <h5 className="card-title">{projectTitle}</h5>
                  <h6 className="my-2">{projectDate}</h6>
                  <p className="my-2">{projectLocation}</p>
               </div>

               <div className="mb-3 text-center">
                  <MDBIcon
                     className="mx-3 cardIcons"
                     onClick={toggleShow1}
                     fas
                     icon="edit"
                     size="2x"
                  />

                  <MDBIcon
                     className="mx-3 cardIcons"
                     onClick={toggleShow2}
                     size="2x"
                     fas
                     icon="trash"
                  />
               </div>
            </MDBCard>
         </MDBCol>
         {/* Modal Readmore */}
         <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered>
               <MDBModalContent>
                  <MDBModalHeader>
                     <MDBModalTitle>{projectTitle}</MDBModalTitle>
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow}
                     ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                     <div className="mb-2">
                        <img
                           src={projectImageLink}
                           className="img-thumbnail"
                           alt=""
                        />
                     </div>
                     <h6>Description</h6>
                     <p>{projectDescription}</p>
                     <h6>Project Type</h6>
                     <p>
                        <em>{projectType}</em>
                     </p>
                  </MDBModalBody>
                  <MDBModalFooter>
                     <MDBBtn color="secondary" onClick={toggleShow}>
                        Got it
                     </MDBBtn>
                  </MDBModalFooter>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>

         {/* Modal Update */}
         <MDBModal
            tabIndex="-1"
            show={centredModal1}
            setShow={setCentredModal1}
         >
            <MDBModalDialog centered>
               <MDBModalContent>
                  <div className="text-end pt-3 pe-3">
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow1}
                     ></MDBBtn>
                  </div>
                  <MDBModalBody>
                     <MDBCard
                        className="text-black  pt-2 needs-validation"
                        style={{ borderRadius: "25px" }}
                     >
                        <MDBCardBody>
                           <MDBRow
                              tag="form"
                              className="g-3 justify-content-center align-items-center"
                              onSubmit={(e) => updateProject(e)}
                           >
                              <h3 className="fw-bold mb-3 mx-1 mx-md-4 ">
                                 Update your project
                              </h3>
                              <MDBCol md={6}>
                                 <MDBInput
                                    value={formValue.projectTitle}
                                    name="projectTitle"
                                    onChange={onChange}
                                    label="Project Title"
                                    id="projectTitleId"
                                    type="text"
                                    className="w-100"
                                    required
                                 />
                              </MDBCol>
                              <MDBCol md={6}>
                                 <MDBInput
                                    value={formValue.projectLocation}
                                    name="projectLocation"
                                    onChange={onChange}
                                    label="Project Location"
                                    id="projectLocationId"
                                    type="text"
                                    className="w-100"
                                    required
                                 />
                              </MDBCol>
                              <MDBCol md={6}>
                                 <Form.Select
                                    aria-label="Default select example"
                                    onChange={onChange}
                                    value={formValue.projectType}
                                    name="projectType"
                                    required
                                 >
                                    <option value="">Project Type</option>
                                    <option value="Installation">
                                       Installation
                                    </option>
                                    <option value="Operational">
                                       Operational
                                    </option>
                                    <option value="Commissioning">
                                       Commissioning
                                    </option>
                                 </Form.Select>
                              </MDBCol>
                              <MDBCol md={6}>
                                 <MDBInput
                                    value={formValue.projectDate}
                                    name="projectDate"
                                    onChange={onChange}
                                    label="Date Completed"
                                    id="projectDateId"
                                    type="date"
                                    className="w-100"
                                    required
                                 />
                              </MDBCol>
                              <MDBCol md={12}>
                                 <MDBInput
                                    value={formValue.projectImageLink}
                                    name="projectImageLink"
                                    onChange={onChange}
                                    label="Project Image Link"
                                    id="imageId"
                                    type="url"
                                    className="w-100"
                                    required
                                 />
                              </MDBCol>
                              <MDBCol md={12}>
                                 <MDBTextArea
                                    value={formValue.projectDescription}
                                    name="projectDescription"
                                    onChange={onChange}
                                    label="Project Description"
                                    id="projectDescriptionId"
                                    type="text"
                                    rows={8}
                                    className="w-100"
                                    required
                                 />
                              </MDBCol>
                              <MDBCol size="12" className="text-center">
                                 {saving ? (
                                    <MDBBtn
                                       type="submit"
                                       disabled
                                       color="warning"
                                       outline
                                    >
                                       Saving..
                                    </MDBBtn>
                                 ) : (
                                    <MDBBtn
                                       type="submit"
                                       className="buttonOutline"
                                       color="warning"
                                       outline
                                    >
                                       Save
                                    </MDBBtn>
                                 )}
                              </MDBCol>
                           </MDBRow>
                        </MDBCardBody>
                     </MDBCard>
                  </MDBModalBody>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>

         {/* Modal delete */}
         <MDBModal
            tabIndex="-1"
            show={centredModal2}
            setShow={setCentredModal2}
         >
            <MDBModalDialog centered>
               <MDBModalContent>
                  <div className="text-end pt-3 pe-3">
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow2}
                     ></MDBBtn>
                  </div>
                  <MDBModalBody>
                     <MDBRow
                        tag="form"
                        className="g-3 justify-content-center align-items-center"
                        onSubmit={(e) => deleteProject(e)}
                     >
                        <h3 className="text-center    fw-bold mb-3 mx-1 mx-md-4 ">
                           Are you sure?
                        </h3>

                        <MDBCol size="12" className="text-center">
                           {deleting ? (
                              <MDBBtn type="submit" disabled color="danger">
                                 Deleting..
                              </MDBBtn>
                           ) : (
                              <MDBBtn type="submit" color="danger" outline>
                                 Yes
                              </MDBBtn>
                           )}
                        </MDBCol>
                     </MDBRow>
                  </MDBModalBody>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>
      </>
   );
}
