import React, { useState } from "react";
import {
   MDBBtn,
   MDBContainer,
   MDBRow,
   MDBCol,
   MDBCard,
   MDBCardBody,
   MDBInput,
} from "mdb-react-ui-kit";
import { Navigate, useNavigate } from "react-router-dom";

function Login() {
   const navigate = useNavigate();
   const retrieveToken = localStorage.getItem("token");

   const [invalid, setInvalid] = useState(false);
   const [sending, setSending] = useState(false);

   const [formValue, setFormValue] = useState({
      username: "",
      password: "",
   });
   const onChange = (e) => {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
   };

   const login = (e) => {
      e.preventDefault();
      setSending(true);
      setInvalid(false);
      fetch(`${process.env.REACT_APP_API_URL}/admin/login`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            username: formValue.username,
            password: formValue.password,
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            if (typeof data.access !== "undefined") {
               localStorage.setItem("token", data.access);
               setInvalid(false);
               setSending(false);
               navigate("/admin/adminPage");
            } else {
               setInvalid(true);
               setSending(false);
            }
         });
   };

   return retrieveToken !== null ? (
      <Navigate to="/admin/adminPage" />
   ) : (
      <MDBContainer className="loginContainer d-flex align-items-center justify-content-center">
         <MDBRow className="">
            <MDBCol md={12}>
               <MDBCard onSubmit={(e) => login(e)} tag="form" className="p-5 ">
                  <div className="text-center">
                     <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                     <p>Please enter your login and password!</p>
                  </div>
                  <MDBCardBody className="m-0 p-0">
                     {invalid ? (
                        <span className="text-danger d-block">
                           Invalid Credentials. Please try again
                        </span>
                     ) : (
                        <span className="text-danger d-none">
                           Invalid Credentials. Please try again
                        </span>
                     )}

                     <MDBInput
                        value={formValue.username}
                        name="username"
                        onChange={onChange}
                        label="Username"
                        className="my-3"
                        type="text"
                        required
                     />
                     <MDBInput
                        value={formValue.password}
                        name="password"
                        onChange={onChange}
                        label="Password"
                        className="my-3"
                        type="password"
                        required
                     />
                     <div className="text-center">
                        {sending ? (
                           <MDBBtn
                              outline
                              className="mx-2 px-5"
                              size="lg"
                              disabled
                           >
                              Logging in...
                           </MDBBtn>
                        ) : (
                           <MDBBtn
                              outline
                              className="mx-2 px-5"
                              size="lg"
                              type="submit"
                           >
                              Login
                           </MDBBtn>
                        )}
                     </div>
                  </MDBCardBody>
               </MDBCard>
            </MDBCol>
         </MDBRow>
      </MDBContainer>
   );
}

export default Login;
