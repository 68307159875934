import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";

import Home from "./pages/Home";
import ProjectLists from "./pages/Projects";
import LoginAdmin from "./pages/LoginAdmin";
import AdminPage from "./pages/AdminPage";

import NotFound from "./pages/NotFound";
import AllInquries from "./components/adminComponents/AllInquiries";
import AddProject from "./pages/AddProject";

function App() {
   return (
      <Router>
         <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/projects" element={<ProjectLists />} />
            <Route exact path="/admin/login" element={<LoginAdmin />} />
            <Route exact path="/admin/adminPage" element={<AdminPage />} />
            <Route exact path="/admin/inquiry" element={<AllInquries />} />
            <Route exact path="/admin/addProject" element={<AddProject />} />
            <Route path="*" element={<NotFound />} />
         </Routes>
      </Router>
   );
}

export default App;
