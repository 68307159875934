import AllProjectsAdmin from "../components/adminComponents/AllProjectsAdmin";

import AdminNavbar from "../components/adminComponents/AdminNavbar";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function AdminPage() {
   const [isAdmin, setIsAdmin] = useState(true);
   const retrieveToken = localStorage.getItem("token");
   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/admin/details`, {
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
         },
      })
         .then((res) => res.json())
         .then((data) => {
            if (data !== undefined) {
               setIsAdmin(data.isAdmin);
            } else {
               setIsAdmin(false);
            }
         });
   }, [isAdmin]);

   return !isAdmin ? (
      <Navigate to="/" />
   ) : (
      <>
         <AdminNavbar />
         <AllProjectsAdmin />
      </>
   );
}
