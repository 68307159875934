import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import image1 from "../assets/energy-icons-collection/image1.png";
import image2 from "../assets/energy-icons-collection/image2.png";
import image3 from "../assets/energy-icons-collection/image3.png";
import loading from "../images/Interwind-1s-35px.gif";

export default function Accoplishments() {
   const [data, setData] = useState([]);

   const [installed, setInstalled] = useState([]);
   const [commissioned, setCommissioned] = useState([]);
   const [operational, setOperational] = useState([]);

   const numberOfCommissioned = commissioned.length;
   const numberOfInstalled = installed.length;
   const numberOfOperational = operational.length;

   const [loader, setLoader] = useState(false);

   const installation = data.filter((installedProjects) => {
      return installedProjects.projectType === "Installation";
   });
   const commissioning = data.filter((installedProjects) => {
      return installedProjects.projectType === "Commissioning";
   });
   const operation = data.filter((installedProjects) => {
      return installedProjects.projectType === "Operational";
   });

   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/project/`)
         .then((res) => res.json())
         .then((data) => {
            setData(
               data.map((project) => {
                  return project;
               })
            );
            setInstalled(installation);
            setCommissioned(commissioning);
            setOperational(operation);
            setLoader(true);
         });
   }, [installed, commissioned, operational]);

   return (
      <div className="accomplishmentBackground d-flex align-items-center ">
         <MDBContainer>
            <MDBRow className="py-5">
               <MDBCol
                  lg={4}
                  className="my-3 d-flex justify-content-center flex-column align-items-center"
               >
                  <img
                     className="accomplishmentIcons my-4"
                     src={image1}
                     alt=""
                  />
                  {!loader ? (
                     <img src={loading} alt="" />
                  ) : (
                     <h1 className="fw-light">
                        {numberOfInstalled === 0 ? (
                           <div>
                              <img src={loading} alt="" />
                           </div>
                        ) : (
                           numberOfInstalled
                        )}
                     </h1>
                  )}
                  <h5 className="fw-light letterSpacing">
                     Installed Solar Panels
                  </h5>
               </MDBCol>
               <MDBCol
                  lg={4}
                  className="my-3 d-flex justify-content-center flex-column align-items-center"
               >
                  <img
                     className="accomplishmentIcons my-4"
                     src={image2}
                     alt=""
                  />
                  {!loader ? (
                     <img src={loading} alt="" />
                  ) : (
                     <h1 className="fw-light">
                        {numberOfOperational === 0 ? (
                           <div>
                              <img src={loading} alt="" />
                           </div>
                        ) : (
                           numberOfOperational
                        )}
                     </h1>
                  )}
                  <h5 className="fw-light letterSpacing">
                     Operational Systems
                  </h5>
               </MDBCol>
               <MDBCol
                  lg={4}
                  className="my-3 d-flex justify-content-center flex-column align-items-center"
               >
                  <img
                     className="accomplishmentIcons my-4"
                     src={image3}
                     alt=""
                  />
                  {!loader ? (
                     <img src={loading} alt="" />
                  ) : (
                     <h1 className="fw-light">
                        {numberOfCommissioned === 0 ? (
                           <div>
                              <img src={loading} alt="" />
                           </div>
                        ) : (
                           numberOfCommissioned
                        )}
                     </h1>
                  )}

                  <h5 className="fw-light letterSpacing">
                     Commissioned Systems
                  </h5>
               </MDBCol>
            </MDBRow>
         </MDBContainer>
      </div>
   );
}
