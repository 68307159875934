import React, { useState } from "react";
import {
   MDBContainer,
   MDBNavbar,
   MDBNavbarBrand,
   MDBNavbarToggler,
   MDBNavbarNav,
   MDBNavbarItem,
   MDBNavbarLink,
   MDBCollapse,
   MDBIcon,
   MDBBtn,
   MDBModal,
   MDBModalDialog,
   MDBModalContent,
   MDBModalHeader,
   MDBModalTitle,
   MDBModalBody,
   MDBModalFooter,
} from "mdb-react-ui-kit";

import businesIcon from "../../images/ICON.png";
import loader from "../../images/Dual Ring-0.5s-25px.gif";

export default function Navbar() {
   const [showNav, setShowNav] = useState(false);

   const [isLogout, setIsLogout] = useState(false);

   const [centredModal1, setCentredModal1] = useState(false);

   const toggleShow1 = () => setCentredModal1(!centredModal1);

   function logout() {
      setIsLogout(!isLogout);
      localStorage.clear();
      window.location.reload();
   }

   return (
      <>
         <MDBNavbar expand="lg" light bgColor="light" fixed="top">
            <MDBContainer className="d-flex justift-content-between">
               <MDBNavbarBrand href="/admin/adminPage">
                  <img className="navBrandIcon" src={businesIcon} alt="" />
               </MDBNavbarBrand>
               <MDBNavbarToggler
                  type="button"
                  aria-expanded="true"
                  aria-label="Toggle navigation"
                  onClick={() => setShowNav(!showNav)}
               >
                  {showNav ? (
                     <MDBIcon fas icon="times" />
                  ) : (
                     <MDBIcon icon="bars" fas />
                  )}
               </MDBNavbarToggler>
               <MDBCollapse navbar show={showNav} className="my-2">
                  <MDBNavbarNav>
                     <MDBNavbarItem>
                        <MDBNavbarLink
                           aria-current="page"
                           href="/admin/inquiry"
                        >
                           Inquiries
                        </MDBNavbarLink>
                     </MDBNavbarItem>
                     <MDBNavbarItem>
                        <MDBNavbarLink
                           aria-current="page"
                           href="/admin/addProject"
                        >
                           Add Project
                        </MDBNavbarLink>
                     </MDBNavbarItem>
                  </MDBNavbarNav>
                  <div className="text-end">
                     <MDBBtn
                        className=""
                        rounded
                        color="danger"
                        onClick={toggleShow1}
                     >
                        Logout
                     </MDBBtn>
                  </div>
               </MDBCollapse>
            </MDBContainer>
         </MDBNavbar>

         {/* Modal */}
         <MDBModal
            tabIndex="-1"
            show={centredModal1}
            setShow={setCentredModal1}
         >
            <MDBModalDialog centered scrollable>
               <MDBModalContent>
                  <div className="text-end p-2">
                     <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShow1}
                     ></MDBBtn>
                  </div>

                  <MDBModalBody className="text-center">
                     <h2 className="fw-normal text-center">Are you sure?</h2>
                     <h5 className="fw-light mt-2">This can't be undone.</h5>
                  </MDBModalBody>
                  <MDBModalFooter>
                     <MDBBtn color="secondary" onClick={toggleShow1}>
                        Cancel
                     </MDBBtn>
                     {isLogout ? (
                        <MDBBtn color="danger" disabled>
                           <img
                              className="loaderImage p-0 m-0"
                              img-fluid
                              src={loader}
                              alt=""
                           />
                        </MDBBtn>
                     ) : (
                        <MDBBtn color="danger" onClick={logout}>
                           Yes
                        </MDBBtn>
                     )}
                  </MDBModalFooter>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>
      </>
   );
}
