import Banner from "../components/Banner";
import Navbar from "../components/Navbar";
import About from "../components/About";
import Footer from "../components/Footer";
import Projects from "../components/Projects";
import Accoplishments from "../components/Accomplishment";
import Services from "../components/Services";

import { useEffect, useState } from "react";

import loader from "../images/Ball-1s-250px.gif";

export default function Home() {
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/project/`)
         .then((res) => res.json())
         .then((data) => {
            if (data.length === 0) {
               setLoading(false);
            } else {
               setLoading(false);
            }
         });
   }, []);

   return loading ? (
      <div className="min-100vh d-flex justify-content-center align-items-center flex-column">
         <img src={loader} alt="" />
         <h2>Loading....</h2>
      </div>
   ) : (
      <>
         <Navbar />
         <Banner />
         <About />
         <Accoplishments />
         <Projects />
         <Services />
         <Footer />
      </>
   );
}
