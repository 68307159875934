import { MDBIcon } from "mdb-react-ui-kit";

export default function NotFound() {
   return (
      <>
         <div className="min-100vh d-flex justify-content-center align-items-center flex-column">
            <h1 className="notFoundHeading">404</h1>
            <h2>Page Not found</h2>
            <p>You are trying to access a broken/not available URL </p>
            <div>
               <MDBIcon fas icon="angle-double-left" />
               <a href="/" className="">
                  Back to Home
               </a>
            </div>
         </div>
      </>
   );
}
