import {
   MDBBtn,
   MDBCol,
   MDBContainer,
   MDBRow,
   MDBModal,
   MDBModalDialog,
   MDBModalContent,
   MDBModalHeader,
   MDBModalTitle,
   MDBModalBody,
   MDBModalFooter,
   MDBTextArea,
   MDBInput,
   MDBCardBody,
   MDBCard,
   MDBIcon,
} from "mdb-react-ui-kit";

import { useState } from "react";

export default function Footer() {
   const date = new Date();
   const year = date.getFullYear();

   const [centredModal, setCentredModal] = useState(false);

   const [formValue, setFormValue] = useState({
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      email: "",
      message: "",
   });

   const toggleShow = () => {
      setCentredModal(!centredModal);
      setFormValue({
         firstName: "",
         lastName: "",
         contactNumber: "",
         address: "",
         email: "",
         message: "",
      });
      setLoading(false);
   };

   const [loading, setLoading] = useState(false);
   const [sent, setSent] = useState(false);

   const onChange = (e) => {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
   };

   function sendMessage(e) {
      e.preventDefault();
      setLoading(true);

      fetch(`${process.env.REACT_APP_API_URL}/inquiry/`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            email: formValue.email,
            contactNumber: formValue.contactNumber,
            address: formValue.address,
            message: formValue.message,
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            if (data) {
               setSent(true);
            } else {
               setSent(false);
            }
         });
   }

   function updateSent(e) {
      setSent(false);
      toggleShow();
   }

   return (
      <>
         <div className="footer-container">
            <MDBContainer className="footer">
               <MDBRow className="d-flex py-5">
                  <MDBCol id="contact" lg={5} className="mb-4 p-3">
                     <h3>Contact Us</h3>
                     <p>
                        Feel free to get in touch with us. We are always open to
                        discussing new projects.
                     </p>
                     <div className="text-md-end">
                        <MDBBtn
                           className="mt-2 blueButton"
                           onClick={toggleShow}
                        >
                           Make an inquiry
                        </MDBBtn>
                     </div>
                  </MDBCol>
                  <MDBCol lg={7} className="px-3">
                     <MDBRow>
                        <MDBCol md={4} className="my-1">
                           <h5 className="text-underline fw-normal">
                              Office Details
                           </h5>
                           <p className="m-0 fw-light">Mobo, Masbate</p>
                           <p className="m-0 fw-light">
                              0912341232 / 0912341232
                           </p>
                           <a
                              target="_blank"
                              href="https://goo.gl/maps/CjsiotGdhVzXTuYp7"
                              className="fw-bold color-orange"
                           >
                              Get Directions
                           </a>
                        </MDBCol>
                        <MDBCol md={4} className="my-1">
                           <h5 className="text-underline fw-normal">
                              Services
                           </h5>
                           <ul className="services-footer-lists">
                              <li className="m-0 p-0 fw-light">
                                 Solar Panel Installation
                              </li>
                              <li className="m-0 p-0 fw-light">
                                 Testing and Commissioning
                              </li>
                              <li className="m-0 p-0 fw-light">
                                 Maintenance and Technical Support
                              </li>
                              <li className="m-0 p-0 fw-light">
                                 Supply and Delivery
                              </li>
                           </ul>
                        </MDBCol>
                        <MDBCol md={4} className="my-1">
                           <h5 className="text-underline fw-normal">Socials</h5>
                           <div className="my-3">
                              <a
                                 target="_blank"
                                 href="https://www.facebook.com/profile.php?id=100086059574063"
                              >
                                 <MDBIcon
                                    className="color-orange mx-2"
                                    fab
                                    icon="facebook"
                                    size="2x"
                                 />
                              </a>
                              <a
                                 target="_blank"
                                 href="https://www.linkedin.com"
                              >
                                 <MDBIcon
                                    className="color-orange mx-2"
                                    fab
                                    icon="linkedin"
                                    size="2x"
                                 />
                              </a>
                              <a
                                 target="_blank"
                                 href="mailto:malucernas@debesmscat.edu.ph"
                              >
                                 <MDBIcon
                                    className="color-orange mx-2"
                                    fas
                                    icon="envelope"
                                    size="2x"
                                 />
                              </a>
                           </div>
                        </MDBCol>
                     </MDBRow>
                  </MDBCol>
               </MDBRow>
               <div className="text-center">
                  <p className="m-0 pb-3 fw-light footerText">
                     BZ Solar Power <br /> Engineering Services &copy; {year}
                  </p>
               </div>
            </MDBContainer>
         </div>

         {/* Modal */}
         <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered>
               <MDBModalContent>
                  {sent ? (
                     <></>
                  ) : (
                     <div className="text-end pt-3 pe-3">
                        <MDBBtn
                           className="btn-close"
                           color="none"
                           onClick={toggleShow}
                        ></MDBBtn>
                     </div>
                  )}

                  <MDBModalBody>
                     <MDBCard
                        className="text-black needs-validation"
                        style={{ borderRadius: "25px" }}
                     >
                        {sent ? (
                           <></>
                        ) : (
                           <h5 className="text-center pt-3 fw-normal">
                              Need More Details? <br /> Submit an Inquiry Today
                           </h5>
                        )}

                        {!sent ? (
                           <MDBCardBody>
                              <MDBRow
                                 tag="form"
                                 className="g-3 justify-content-center align-items-center"
                                 onSubmit={(e) => sendMessage(e)}
                              >
                                 <MDBCol md="6">
                                    <MDBInput
                                       value={formValue.firstName}
                                       name="firstName"
                                       onChange={onChange}
                                       label="First Name"
                                       id="firstNameId"
                                       type="text"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="6">
                                    <MDBInput
                                       value={formValue.lastName}
                                       name="lastName"
                                       onChange={onChange}
                                       label="Last Name"
                                       id="lastNameId"
                                       type="text"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>

                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.email}
                                       name="email"
                                       onChange={onChange}
                                       label="Email"
                                       id="emailId"
                                       type="email"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.contactNumber}
                                       name="contactNumber"
                                       onChange={onChange}
                                       label="Contact Number"
                                       id="contactId"
                                       type="number"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBInput
                                       value={formValue.address}
                                       name="address"
                                       onChange={onChange}
                                       label="Address"
                                       id="addressId"
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol md="12">
                                    <MDBTextArea
                                       value={formValue.message}
                                       name="message"
                                       onChange={onChange}
                                       label="Message"
                                       id="messageId"
                                       type="text"
                                       rows={8}
                                       className="w-100"
                                       required
                                    />
                                 </MDBCol>
                                 <MDBCol size="12" className="text-center">
                                    <MDBBtn
                                       rounded
                                       type="submit"
                                       className="blueButton"
                                       disabled={loading}
                                    >
                                       {loading ? "Sending..." : "Submit"}
                                    </MDBBtn>
                                 </MDBCol>
                              </MDBRow>
                           </MDBCardBody>
                        ) : (
                           <MDBCardBody>
                              <h1 className="my-5  ">Inquiry sent!</h1>
                              <h4 className="my-5  ">I'll get touch on you.</h4>
                              <MDBBtn
                                 className="blueButton"
                                 onClick={updateSent}
                              >
                                 Got it!
                              </MDBBtn>
                           </MDBCardBody>
                        )}
                     </MDBCard>
                  </MDBModalBody>
               </MDBModalContent>
            </MDBModalDialog>
         </MDBModal>
      </>
   );
}
