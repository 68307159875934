import { useEffect, useState } from "react";
import InquiryCard from "./InquiryCard";
import { Navigate } from "react-router-dom";

import AdminNavbar from "./AdminNavbar";
import { MDBContainer, MDBTable, MDBTableHead } from "mdb-react-ui-kit";

export default function AllInquries() {
   const retrieveToken = localStorage.getItem("token");
   const [inquiry, setInquiry] = useState([]);

   const [isAdmin, setIsAdmin] = useState(true);
   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/admin/details`, {
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
         },
      })
         .then((res) => res.json())
         .then((data) => {
            if (data !== undefined) {
               setIsAdmin(data.isAdmin);
            } else {
               setIsAdmin(false);
            }
         });
   }, [isAdmin]);

   useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/inquiry/`, {
         headers: {
            Authorization: `Bearer ${retrieveToken}`,
         },
         "Content-Type": "application/json",
      })
         .then((res) => res.json())
         .then((data) => {
            if (data) {
               setInquiry(
                  data.map((inquiry) => {
                     return (
                        <InquiryCard key={inquiry._id} inquiryProps={inquiry} />
                     );
                  })
               );
            }
         });
   }, []);

   return !isAdmin ? (
      <Navigate to="/" />
   ) : (
      <MDBContainer className="mt-5 pt-5">
         <AdminNavbar />
         {inquiry.length === 0 ? (
            <div className="text-center">
               <h1 className="fw-light">No client inquiries available</h1>
            </div>
         ) : (
            <>
               <div className="text-center">
                  <h1 className="fw-light my-4">Pending inquiries</h1>
               </div>

               <div className="d-md-flex flex-wrap justify-content-center">
                  {inquiry}
               </div>
            </>
         )}
      </MDBContainer>
   );
}
